import request from '@/utils/request'

// 分类列表
export function articleCateList() {
	return request({
		url: '/admin/article/cateList',
		method: 'get'
	})
}
//保存 修改分类
export function saveCate(data) {
	return request({
		url: '/admin/article/saveCate',
		method: 'post',
        data
	})
}
// 删除分类
export function delCate(params) {
	return request({
		url: '/admin/article/delCate',
		method: 'delete',
        params
	})
}


// 文章列表
export function articleList(params) {
	return request({
		url: '/admin/article/index',
		method: 'get',
		params
	})
}
//保存 修改文章
export function saveArticle(data) {
	return request({
		url: '/admin/article/save',
		method: 'post',
        data
	})
}
// 删除文章
export function delArticle(params) {
	return request({
		url: '/admin/article/delete',
		method: 'delete',
        params
	})
}

// 删除文章
export function articleInfo(id) {
	return request({
		url: `/admin/article/info/${id}`,
		method: 'get',
	})
}




