<template>
	<div v-loading.lock="fullscreenLoading">
        <div class="search-container">
            <div
                style="margin-left: auto; display: flex; justify-content: space-around"
            >
                <div class="search-container-fn-input">
                    <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-document-add"
                        style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                                background-color: #2362FB; border-color: #2362FB"
                        @click="handleAdd()"
                        >新增文章
                    </el-button>
                </div>

                <div class="search-container-fn-input">
                    <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                                background-color: #2362FB; border-color: #2362FB"
                        @click="handleAdd('update')"
                        >修改文章
                    </el-button>
                </div>

                <div class="search-container-fn-input">
                    <el-button
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;"
                        @click="handleDel()"
                        >删除文章
                    </el-button>
                </div>

            </div>
        </div>

        <activeTable ref="actTable" :tableHead="table_options[optionsName]" :tableData="tableData" 
        @onTextBtn="handleBtn" @onSearch="handleSearch"></activeTable>

        <Pagination
            :total="total"
            :page.sync="searchParams.page"
            :limit.sync="searchParams.limit"
            @pagination="getList"
        />
		
        <!-- 新增文章 -->
        <el-dialog
            :title="title"
            :visible.sync="centerDialogVisible"
            width="800"
            center>
            <el-form :model="form" :rules="rules" size="mini" :label-width="formLabelWidth">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="form.title" autocomplete="off" :style="{width:formValueWidth+'px'}"> </el-input>
                </el-form-item>
                <el-form-item label="分类" prop="article_cate_id">
                    <el-select v-model="form.article_cate_id" placeholder="请选择" :style="{width:formValueWidth+'px'}">
                        <el-option
                            v-for="item in cateOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="封面图" prop="cover_pic">
                    <UploadImage :limit="1" ref="upload_img" :url="form.cover_pic ? [form.cover_pic] : []"></UploadImage>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <tinymce v-model="form.content" :height="300" :width="formValueWidth"/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="handlSaveArticle" size="mini">确 定</el-button>
            </span>
            </el-dialog>
	</div>
</template>

<script>
import {
    articleList,
    articleCateList,
    saveArticle,
    delArticle,
    articleInfo
} from "@/api/article/index"
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import activeTable from "@/components/activeTable/index"
import UploadImage from "@/components/UploadImage/UploadImage"
import {mapGetters, mapState} from "vuex";
import Tinymce from '@/components/Tinymce'
export default {
  name: "articleList",
  async created () {
    await this._getCateList()
    this.getList()
  },
  components: { activeTable,Pagination,UploadImage,Tinymce },
  computed: {
    ...mapGetters(["adminId", "agentId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
      table_options: state => state.user.table_options,
    }),
  },
  data () {
    return {
        searchParams:{
            page:1,
            limit:20
        },
        total:0,
        optionsName:"article",
        tableData:[],
        fullscreenLoading:true,
        centerDialogVisible:false,
        form:{
            name:"",
            sort:0,
            cover_pic:""
        },
        rules:{
            title:[{ required: true, message: '请输入标题', trigger: 'blur' }],
            article_cate_id:[{ required: true, message: '请选择分类', trigger: 'change' }],
            cover_pic:[{ required: true, message: '请上传封面图', trigger: 'change' }],
            content:[{ required: true, message: '请填写内容', trigger: 'blur' }],
        },
        formLabelWidth: '120px',
        formValueWidth: 700,
        title:"新增文章",
        cateOptions:[],
        searchTableHead: [
            {
                name: 'cate_name', // ?
                isSearch: false,
                type:"select",
                options:[]
            }
        ]
    }
  },
  methods: {
    handleBtn(e){
        console.log(2222,e)
    },
    handleSearch(e){
        this.searchParams[e.column] = e.value
        this.getList("restPage")
    },
    async _getCateList(){
        const res = await articleCateList()
        if(res.code == 200){
            this.cateOptions = res.data.map(item => {
                return {
                    value:item.id,
                    label:item.name
                }
            })
        }
    },
    _buildSearch(){
        this.table_options[this.optionsName].forEach(item=>{
            if(item.column_prop == "cate_name"){
                item.search_data = this.cateOptions
            }else{
                item.search_data = []
            }
        })
    },
    getList(type){
        if (type == "restPage") {
            this.searchParams.page = 1;
        }
        this.fullscreenLoading = true
        articleList( this.searchParams).then(res=>{
            this.fullscreenLoading = false
            if (res.code == 200){
                this._buildSearch()
                this.tableData = res.data.list  
                this.total = res.data.total          
            }
        })  
    },
   
    handleAdd(type = "add"){    
        if (type == "add"){
            this.form = {
                name:"",
                sort:0,
                cover_pic:"" 
            }
            this.title = "新增文章"
            this.centerDialogVisible = true
        }else{
            let res = this._checkSelection()
            if (!res){
                return 
            }
            this.title = "修改文章"
            articleInfo(this.$refs["actTable"].multipleSelectionItem[0].id).then(res=>{
                if(res.code == 200){
                    this.form = res.data
                    this.centerDialogVisible = true
                }
            })      
        }
         
       
    },
    handleDel(){
        let res = this._checkSelection()
        if (!res){
            return 
        }
        this.$confirm('确认删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.fullscreenLoading = true
            delArticle({id:this.$refs["actTable"].multipleSelection[0]}).then(res => {
                this.fullscreenLoading = false
                if(res.code == 200){
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }
            })
        }).catch(() => {
            this.fullscreenLoading = false
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
        });
    },
    _checkSelection(){
        if (this.$refs["actTable"].multipleSelectionItem.length > 1){
            this.$message.error("只能选择一条记录操作")
            return false
        }
        if (this.$refs["actTable"].multipleSelectionItem.length == 0){
            this.$message.error("请选择")
            return false
        }
        return true
    },

    handlSaveArticle(){
        this.fullscreenLoading = true
        if(!this.form.cover_pic){
            this.form.cover_pic = this.$refs["upload_img"].imageUrl
            if(!this.form.cover_pic){
                return this.$message.error("请上传封面图")
            }
        }
       
        saveArticle(this.form).then(res => {
            this.fullscreenLoading = false
            if (res.code == 200){
                this.getList()
                this.$message.success("操作成功")
                this.centerDialogVisible = false
            }else{
                this.$message.error(res.msg)
            }
        })
    }
  }
}
</script>

<style lang="scss">
	.avatar {
		height: 200px;
		width: auto;
	}
	
	.el-input.is-disabled {
		.el-input__inner {
			border: none;
			background: transparent;
			color: #333;
		}
		
		.el-icon-arrow-down {
			display: none;
		}
		
		.el-input__suffix {
			display: none;
		}
	}
	
	.preContent {
		margin: 0;
		overflow: auto;
		white-space: pre-wrap;
		border: 1px solid #ececec;
		
		p {
			margin: 0;
			padding: 0;
		}
	}
	
	.imgUpload {
		background: url("../../assets/icon_upload.png") no-repeat;
		background-position: center;
		width: 160px;
		height: 110px;
		background-size: cover;
		
	}
	
	.width278 {
		width: 578px;
		
		img {
			width: 100%;
			height: auto;
		}
	}
	
	.el-form-item {
		margin-bottom: 30px;
	}
	
	.bbox {
		.el-form-item {
			display: flex;
			align-items: center;
			
			.el-form-item__content {
				margin-left: 0 !important;
			}
		}
	}
</style>
